import React, { useCallback, useRef } from 'react'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { ArticleCreateRequest, ArticleCreateResponse } from '../types/Article'
import axios, { AxiosError } from 'axios'
import urlJoin from 'url-join'
import 'dayjs/locale/ko'
import dayjs from 'dayjs'

dayjs.locale('ko')

const backendUrl = process.env.REACT_APP_BACKEND_URL!

export default function NewArticlePage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const isReply = searchParams.get('isReply') === 'true'
  const replyFor = isReply ? Number(searchParams.get('replyFor')) : null
  const replyForTitle = searchParams.get('replyForTitle')

  const titleRef = useRef<HTMLInputElement>(null)
  const contentRef = useRef<HTMLTextAreaElement>(null)
  const authorRef = useRef<HTMLInputElement>(null)
  const passcodeRef = useRef<HTMLInputElement>(null)

  const handleSubmit = useCallback(() => {
    const title = titleRef.current!.value
    const content = contentRef.current!.value
    const author = authorRef.current?.value ?? null
    const passcode = passcodeRef.current?.value ?? null

    if (!title) return alert('제목을 입력하세요.')
    if (!content) return alert('내용을 입력하세요.')
    const accessToken = localStorage.getItem('ACCESS_TOKEN')

    if (!accessToken && !author) return alert('작성자 이름을 입력하세요.')
    if (!accessToken && !passcode) return alert('비밀번호를 입력하세요.')

    const data: ArticleCreateRequest = {
      title,
      content,
      author: accessToken ? null : author,
      isAuthorAdmin: !!accessToken,
      isReply,
      replyFor,
      passcode: accessToken ? null : passcode,
    }

    axios
      .post<ArticleCreateResponse>(urlJoin(backendUrl, '/qna'), data, {
        headers: accessToken
          ? {
              Authorization: `Bearer ${accessToken}`,
            }
          : {},
      })
      .then(({ data }) => {
        alert('성공적으로 등록되었습니다.')
        navigate(`/qna/${data.data.id}`)
      })
      .catch((error: AxiosError) => {
        alert(`오류가 발생했습니다: ${(error.response?.data as any).detail}`)
      })
  }, [])

  return (
    <div className="min-h-screen py-24 text-primary-500">
      <section className="py-12">
        <div className="container mx-auto px-8 sm:px-12 md:px-18 lg:px-24">
          <div className="flex items-end border-b border-primary-100 pb-2">
            <div className="w-full flex items-center gap-4 sm:text-lg md:text-2xl font-light">
              <span className="font-medium flex-shrink-0">제목: </span>
              <input
                ref={titleRef}
                type="text"
                placeholder="제목을 입력하세요"
                defaultValue={
                  replyForTitle ? 'Re: ' + replyForTitle : undefined
                }
                className="w-full rounded-lg border border-primary-200 px-2 py-1 text-primary-800 outline-none"
              />
            </div>
          </div>

          {isReply && (
            <div className="mt-2">
              <span>&quot;{replyForTitle}&quot;</span>{' '}
              <span className="text-primary-700 font-extralight">
                글에 대한 답변을 작성합니다.
              </span>
            </div>
          )}

          <textarea
            ref={contentRef}
            placeholder="내용을 입력하세요"
            className="min-h-[60vh] w-full text-sm sm:text-base leading-relaxed whitespace-pre-line my-4 text-primary-700 font-light bg-primary-100/40 rounded-xl p-3 outline-none"
          />

          <div className="flex flex-col lg:flex-row gap-4 justify-between">
            {!localStorage.getItem('ACCESS_TOKEN') && (
              <div className="flex flex-col lg:flex-row gap-4">
                <div className="flex gap-2 items-center">
                  <div className="flex-shrink-0">이름:</div>
                  <input
                    ref={authorRef}
                    type="text"
                    className="border-b w-full sm:w-auto border-primary-200 text-primary-800 outline-none"
                  />
                </div>

                <div className="flex gap-2 items-center">
                  <div className="flex-shrink-0">비밀번호:</div>
                  <input
                    ref={passcodeRef}
                    type="password"
                    className="border-b w-full sm:w-auto border-primary-200 text-primary-800 outline-none"
                  />
                </div>
              </div>
            )}

            <div className="flex gap-2">
              <Link to="/qna">
                <button
                  type="button"
                  className="border border-primary-400 rounded-full px-6 py-1 text-primary-400 font-extralight"
                >
                  취소
                </button>
              </Link>

              <button
                type="button"
                className="h-fit bg-primary-400 rounded-full px-6 py-1 text-white font-extralight"
                onClick={handleSubmit}
              >
                작성하기
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
