import React, { useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ArticleCreateResponse,
  ArticlePatchRequest,
  ArticleResponse,
} from '../types/Article'
import axios, { AxiosError } from 'axios'
import urlJoin from 'url-join'
import 'dayjs/locale/ko'
import dayjs from 'dayjs'
import useSWR from 'swr'

dayjs.locale('ko')

const backendUrl = process.env.REACT_APP_BACKEND_URL!

export default function EditArticlePage() {
  const { articleId } = useParams()

  const navigate = useNavigate()

  const { data, error } = useSWR<ArticleResponse, AxiosError>(
    urlJoin(backendUrl, `/qna/${articleId}`),
    (url: string) => axios.get(url, {}).then((r) => r.data),
    {
      fallbackData:
        process.env.REACT_APP_TEST_MODE === 'true'
          ? {
              message: 'OK',
              data: {
                id: 3,
                title: '테스트 문의글입니다.',
                content: '안녕하세요.\n\n테스트 문의글입니다.',
                author: '김XX',
                isAuthorAdmin: false,
                createdAt: '2023-12-26T11:01:52.527Z',
                isReply: false,
                replyFor: null,
              },
            }
          : undefined,
    },
  )

  const titleRef = useRef<HTMLInputElement>(null)
  const contentRef = useRef<HTMLTextAreaElement>(null)

  const handleSubmit = useCallback(() => {
    const title = titleRef.current!.value
    const content = contentRef.current!.value

    if (!title) return alert('제목을 입력하세요.')
    if (!content) return alert('내용을 입력하세요.')

    const accessToken = localStorage.getItem('ACCESS_TOKEN')

    let passcode: string | null = null
    if (!accessToken) {
      const pmpt = prompt('수정하려면 비밀번호를 입력하세요.')
      if (!pmpt) return

      passcode = pmpt
    }

    const data: ArticlePatchRequest = {
      title,
      content,
      passcode,
    }

    axios
      .patch<ArticleCreateResponse>(
        urlJoin(backendUrl, `/qna/${articleId}`),
        data,
        {
          headers: accessToken
            ? {
                Authorization: `Bearer ${accessToken}`,
              }
            : {},
        },
      )
      .then(() => {
        alert('성공적으로 수정되었습니다.')
        navigate(`/qna/${articleId}`)
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          alert('비밀번호가 틀립니다.')
        } else {
          alert(`오류가 발생했습니다: ${(error.response?.data as any).detail}`)
        }
      })
  }, [])

  if (!data) {
    return (
      <div className="h-screen flex justify-center items-center text-3xl text-primary font-light">
        {error
          ? `오류가 발생했습니다: ${(error.response?.data as any).detail}`
          : '불러오는 중'}
      </div>
    )
  }

  const articleData = data.data

  return (
    <div className="min-h-screen py-24 text-primary-500">
      <section className="py-12">
        <div className="container mx-auto px-8 sm:px-12 md:px-18 lg:px-24">
          <div className="flex justify-between items-end border-b border-primary-100 pb-2 gap-2">
            <div className="w-full flex items-center gap-4 sm:text-lg md:text-2xl font-light">
              <span className="font-medium flex-shrink-0">제목: </span>
              <input
                ref={titleRef}
                type="text"
                placeholder="제목을 입력하세요"
                defaultValue={articleData.title}
                className="w-full rounded-lg border border-primary-200 px-2 py-1 text-primary-800 outline-none"
              />
            </div>

            <div className="text-xs sm:text-sm font-extralight flex-shrink-0">
              작성자: {articleData.author} |{' '}
              {dayjs(articleData.createdAt).format('YYYY.MM.DD. HH:mm:ss')}
            </div>
          </div>

          <textarea
            ref={contentRef}
            placeholder="내용을 입력하세요"
            defaultValue={articleData.content}
            className="min-h-[60vh] w-full text-sm sm:text-base leading-relaxed whitespace-pre-line my-4 text-primary-700 font-light bg-primary-100/40 rounded-xl p-3 outline-none"
          />

          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="border border-primary-400 rounded-full px-6 py-1 text-primary-400 font-extralight"
              onClick={() => navigate(-1)}
            >
              취소
            </button>

            <button
              type="button"
              className="bg-primary-400 rounded-full px-6 py-1 text-white font-extralight"
              onClick={handleSubmit}
            >
              수정하기
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}
