import React from 'react'
import { Link as ScrollToLink } from 'react-scroll'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../logo.svg'

export default function Navbar() {
  const navigate = useNavigate()

  return (
    <div className="fixed z-[99999] top-0 inset-x-0 h-[72px] bg-white/65 backdrop-blur-lg flex justify-between items-center px-5 text-primary-400 text-sm">
      <Link to="/">
        <img src={logo} />
      </Link>
      <div className="flex items-center gap-6 sm:gap-10 md:gap-12">
        {[
          ['홈', 'home'],
          ['소개', 'intro'],
          ['경력', 'career'],
          ['지원분야', 'businessSector'],
        ].map(([label, name]) => (
          <ScrollToLink
            key={name}
            id={name}
            className="cursor-pointer hidden sm:block"
            to={name}
            spy={true}
            smooth={true}
            duration={500}
            offset={-72}
            onClick={() => {
              navigate('/')
            }}
          >
            {label}
          </ScrollToLink>
        ))}
        <Link to="/qna">
          <button
            type="button"
            className="rounded-full bg-primary-400 text-white px-5 py-1.5"
          >
            문의하기
          </button>
        </Link>
      </div>
    </div>
  )
}
