import React from 'react'

export default function NotFoundPage() {
  return (
    <div className="h-screen bg-primary-100 text-primary-600 flex flex-col justify-center items-center">
      <div className="text-5xl font-bold pb-5">404</div>
      <div className="text-3xl font-light">페이지를 찾을 수 없습니다!</div>
    </div>
  )
}
