import React, { useState } from 'react'
import SectionTitle from '../components/SectionTitle'
import { TbCornerDownRight } from 'react-icons/tb'
import { Link, useSearchParams } from 'react-router-dom'
import urlJoin from 'url-join'
import axios, { AxiosError } from 'axios'
import { ArticlesMinimalResponse } from '../types/Article'
import useSWR from 'swr'
import 'dayjs/locale/ko'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import ReactPaginate from 'react-paginate'
import LoginDialog from '../components/LoginDialog'

dayjs.locale('ko')
dayjs.extend(relativeTime)

const backendUrl = process.env.REACT_APP_BACKEND_URL!

export default function QnAPage() {
  const [searchParams] = useSearchParams()

  const [page, setPage] = useState(Number(searchParams.get('page')) || 1)
  const [loginOpen, setLoginOpen] = useState(false)

  const accessToken = localStorage.getItem('ACCESS_TOKEN')

  const { data, error } = useSWR<ArticlesMinimalResponse, AxiosError>(
    urlJoin(backendUrl, `/qna?page=${page}`),
    (url: string) => axios.get(url, {}).then((r) => r.data),
    {
      fallbackData:
        process.env.REACT_APP_TEST_MODE === 'true'
          ? {
              message: 'OK',
              data: [
                {
                  id: 3,
                  title: '테스트 문의글입니다.',
                  author: '김XX',
                  createdAt: '2023-12-26T11:01:52.527Z',
                  isReply: false,
                },
                {
                  id: 4,
                  title: 'Re: 테스트 문의글입니다.',
                  author: '관리자',
                  createdAt: '2023-12-26T12:09:23.158Z',
                  isReply: true,
                },
              ],
              pageData: {
                current: 1,
                totalCount: 3,
              },
            }
          : undefined,
    },
  )

  if (!data) {
    return (
      <div className="h-screen flex justify-center items-center text-3xl text-primary font-light">
        {error
          ? `오류가 발생했습니다: ${(error.response?.data as any).message}`
          : '불러오는 중'}
      </div>
    )
  }

  return (
    <div className="min-h-screen">
      <section className="py-12">
        <div className="container mx-auto px-8 sm:px-12 md:px-18 lg:px-24">
          <SectionTitle title="Q&A" />

          <table className="w-full text-sm sm:text-base text-primary-700 text-left font-extralight border-spacing-8 table-fixed mb-8">
            <tr className="border-b-2 border-primary-400 text-primary">
              <th className="font-extralight py-3 w-[10%]">순번</th>
              <th className="font-extralight py-3">제목</th>
              <th className="font-extralight py-3 w-[14%]">글쓴이</th>
              <th className="font-extralight py-3 w-[14%]">작성일시</th>
            </tr>
            {data.data.map((item) => {
              return (
                <tr key={item.id} className="border-b border-primary-100">
                  <td className="py-3">{item.id}</td>
                  <td className="py-3 truncate">
                    <Link to={`/qna/${item.id}`}>
                      {item.isReply && (
                        <TbCornerDownRight className="inline-block" />
                      )}{' '}
                      {item.title}
                    </Link>
                  </td>
                  <td className="py-3">{item.author}</td>
                  <td className="py-3">{dayjs(item.createdAt).fromNow()}</td>
                </tr>
              )
            })}
          </table>

          <div className="flex justify-end">
            {!accessToken ? (
              <button
                type="button"
                className="bg-primary-400 rounded-full px-5 py-1 text-white font-extralight mx-2"
                onClick={() => setLoginOpen(true)}
              >
                관리자 로그인
              </button>
            ) : (
              <button
                type="button"
                className="bg-primary-400 rounded-full px-5 py-1 text-white font-extralight mx-2"
                onClick={() => {
                  localStorage.removeItem('ACCESS_TOKEN')
                  location.reload()
                }}
              >
                로그아웃
              </button>
            )}
            {}
            <Link to="/qna-new">
              <button
                type="button"
                className="bg-primary-400 rounded-full px-6 py-1 text-white font-extralight"
              >
                글쓰기
              </button>
            </Link>
          </div>
          <LoginDialog
            open={loginOpen}
            onClose={() => {
              setLoginOpen(false)
            }}
          />
          <ReactPaginate
            breakLabel="..."
            nextLabel="다음 >"
            onPageChange={(event) => {
              setPage(event.selected + 1)
              const url = new URL(window.location.href)
              url.searchParams.set('page', (event.selected + 1 || 1).toString())
              window.history.pushState(null, '', url.toString())
            }}
            pageRangeDisplayed={8}
            initialPage={page - 1}
            pageCount={data.pageData.totalCount}
            previousLabel="< 이전"
            renderOnZeroPageCount={null}
            className="flex gap-4 justify-center mt-3"
            pageClassName="border border-primary-100 w-6 text-sm flex justify-center items-center px-2 py-1"
            previousClassName="border border-primary-100 min-w-6 text-sm flex justify-center items-center px-2 py-1"
            nextClassName="border border-primary-100 min-w-6 text-sm flex justify-center items-center px-2 py-1"
            activeClassName="bg-primary-100 border border-primary-100 min-w-6 text-sm flex justify-center items-center px-2 py-1"
          />
        </div>
      </section>
    </div>
  )
}
