import React from 'react'

interface CareerItemProps {
  title: string
  detail?: string
}

export default function CareerItem({ title, detail }: CareerItemProps) {
  return (
    <div className="flex flex-col md:flex-row md:items-end leading-relaxed gap-2 border-l pl-8 py-2 border-primary-200">
      <div className="text-2xl sm:text-3xl md:text-4xl font-bold">{title}</div>
      <div className="md:text-xl text-primary-400 font-light">{detail}</div>
    </div>
  )
}
