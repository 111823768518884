import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './pages/HomePage'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import QnAPage from './pages/QnAPage'
import NotFoundPage from './pages/NotFoundPage'
import ArticlePage from './pages/ArticlePage'
import ScrollToTop from './components/ScrollToTop'
import NewArticlePage from './pages/NewArticlePage'
import EditArticlePage from './pages/EditArticlePage'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/qna">
          <Route index element={<QnAPage />} />
          <Route path=":articleId">
            <Route index element={<ArticlePage />} />
            <Route path="edit" element={<EditArticlePage />} />
          </Route>
        </Route>
        <Route path="/qna-new" element={<NewArticlePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
