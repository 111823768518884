import React, { useCallback } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { ArticleResponse } from '../types/Article'
import axios, { AxiosError } from 'axios'
import urlJoin from 'url-join'
import useSWR from 'swr'
import 'dayjs/locale/ko'
import dayjs from 'dayjs'

dayjs.locale('ko')

const backendUrl = process.env.REACT_APP_BACKEND_URL!

export default function ArticlePage() {
  const { articleId } = useParams()

  const navigate = useNavigate()

  const { data, error } = useSWR<ArticleResponse, AxiosError>(
    urlJoin(backendUrl, `/qna/${articleId}`),
    (url: string) => axios.get(url, {}).then((r) => r.data),
    {
      fallbackData:
        process.env.REACT_APP_TEST_MODE === 'true'
          ? {
              message: 'OK',
              data: {
                id: 3,
                title: '테스트 문의글입니다.',
                content: '안녕하세요.\n\n테스트 문의글입니다.',
                author: '김XX',
                isAuthorAdmin: false,
                createdAt: '2023-12-26T11:01:52.527Z',
                isReply: false,
                replyFor: null,
              },
            }
          : undefined,
    },
  )

  const handleOnDelete = useCallback(() => {
    if (!confirm('이 글을 삭제하시겠습니까?')) return

    const accessToken = localStorage.getItem('ACCESS_TOKEN')

    let url

    if (accessToken) {
      url = `/qna/${articleId}`
    } else {
      const passcode = prompt('비밀번호를 입력하세요.')

      if (!passcode && passcode !== '') return

      url = `/qna/${articleId}?passcode=${passcode}`
    }

    axios
      .delete(urlJoin(backendUrl, url), {
        headers: accessToken
          ? {
              Authorization: `Bearer ${accessToken}`,
            }
          : undefined,
      })
      .then(() => {
        alert('성공적으로 삭제하였습니다.')
        navigate('/qna')
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          alert('비밀번호가 틀립니다.')
        } else {
          alert(`오류가 발생했습니다: ${(error.response?.data as any).detail}`)
        }
      })
  }, [])

  const handleOnEdit = useCallback(() => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN')

    if (accessToken) {
      return navigate(`/qna/${articleId}/edit`)
    } else {
      const passcode = prompt('비밀번호를 입력하세요.')

      if (!passcode && passcode !== '') return

      axios
        .get(
          urlJoin(backendUrl, `/qna/verify/${articleId}?passcode=${passcode}`),
          {
            headers: accessToken
              ? {
                  Authorization: `Bearer ${accessToken}`,
                }
              : undefined,
          },
        )
        .then(() => {
          navigate(`/qna/${articleId}/edit`)
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            alert('비밀번호가 틀립니다.')
          } else {
            alert(
              `오류가 발생했습니다: ${(error.response?.data as any).detail}`,
            )
          }
        })
    }
  }, [])

  if (!data) {
    return (
      <div className="h-screen flex justify-center items-center text-3xl text-primary font-light">
        {error
          ? `오류가 발생했습니다: ${(error.response?.data as any).detail}`
          : '불러오는 중'}
      </div>
    )
  }

  const articleData = data.data

  return (
    <div className="min-h-screen py-24 text-primary-500">
      <section className="py-12">
        <div className="container mx-auto px-8 sm:px-12 md:px-18 lg:px-24">
          <div className="flex justify-between items-end border-b border-primary-100 pb-2">
            <div className="sm:text-lg md:text-2xl font-light">
              <span className="font-medium">제목: </span>
              <span>{articleData.title}</span>
            </div>

            <div className="text-xs sm:text-sm font-extralight">
              작성자: {articleData.author} |{' '}
              {dayjs(articleData.createdAt).format('YYYY.MM.DD. HH:mm:ss')}
            </div>
          </div>

          <div className="min-h-[60vh] text-sm sm:text-base leading-relaxed whitespace-pre-line my-4 text-primary-700 font-light bg-primary-100/40 rounded-xl p-3">
            {articleData.content}
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="border border-primary-400 rounded-full px-6 py-1 text-primary-400 font-extralight"
              onClick={handleOnEdit}
            >
              수정
            </button>
            <button
              type="button"
              className="border border-primary-400 rounded-full px-6 py-1 text-primary-400 font-extralight"
              onClick={handleOnDelete}
            >
              삭제
            </button>

            {localStorage.getItem('ACCESS_TOKEN') && (
              <Link
                to={`/qna-new?isReply=true&replyFor=${articleId}&replyForTitle=${encodeURIComponent(
                  articleData.title,
                )}`}
              >
                <button
                  type="button"
                  className="bg-primary-400 rounded-full px-6 py-1 text-white font-extralight"
                >
                  답변하기
                </button>
              </Link>
            )}

            <Link to="/qna">
              <button
                type="button"
                className="bg-primary-400 rounded-full px-6 py-1 text-white font-extralight"
              >
                목록으로
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}
