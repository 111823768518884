import classNames from 'classnames'
import React from 'react'

interface SectionTitleProps {
  title: string
  className?: string
}

export default function SectionTitle({ title, className }: SectionTitleProps) {
  return (
    <div className={classNames('flex items-center gap-8 my-16', className)}>
      <hr className="border-primary-200 w-full" />
      <div className="font-extralight text-3xl text-primary flex-shrink-0 tracking-[0.7rem]">
        {title}
      </div>
      <hr className="border-primary-200 w-full" />
    </div>
  )
}
