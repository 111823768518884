import React from 'react'

export default function Footer() {
  return (
    <div className="bg-primary-600 text-primary-100 py-24">
      <div className="container mx-auto px-4 sm:px-8 md:px-12 lg:px-24 flex items-center gap-4 sm:gap-8 md:gap-16 lg:gap-24">
        <div className="flex flex-col gap-2 items-start">
          <div className="logo-font text-2xl sm:text-3xl md:text-4xl">JESI</div>
          <div className="text-xs sm:text-sm md:text-lg font-light">
            특허(ICT) & 컨설팅
          </div>
          <div className="text-sm sm:text-lg md:text-xl font-light">
            대표 <span className="font-extrabold">전 종 성</span>
          </div>
        </div>

        <div className="h-24 border-l border-primary-500" />

        <div className="flex flex-col gap-2 text-xs sm:text-sm md:text-lg font-extralight">
          <div>
            <span className="font-normal">TEL: </span>010-2242-3421
          </div>
          <div>
            <span className="font-normal">E-mail: </span>jasonjun@daum.net
          </div>
          <div>서울시 관악구 행운2길 1 금문B/D 705호</div>
        </div>
      </div>
    </div>
  )
}
