import { Dialog } from '@headlessui/react'
import axios, { AxiosError } from 'axios'
import React, { useRef } from 'react'
import urlJoin from 'url-join'
import { LoginRequest, LoginResponse } from '../types/Login'

interface LoginDialogProps {
  open?: boolean
  onClose?: () => void
}

const backendUrl = process.env.REACT_APP_BACKEND_URL!

export default function LoginDialog({ open, onClose }: LoginDialogProps) {
  const idRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const handleProceedLogin = () => {
    const id = idRef.current!.value
    const password = passwordRef.current!.value

    if (!id) return alert('아이디를 입력하세요.')
    if (!password) return alert('비밀번호를 입력하세요.')

    const data: LoginRequest = { id, password }

    axios
      .post<LoginResponse>(urlJoin(backendUrl, '/login'), data)
      .then((r) => {
        const { accessToken } = r.data

        localStorage.setItem('ACCESS_TOKEN', accessToken)

        alert('로그인 성공')
        if (onClose) onClose()
        location.reload()
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          alert('비밀번호가 틀립니다.')
        } else {
          alert(`오류가 발생했습니다: ${(error.response?.data as any).detail}`)
        }
      })
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose && onClose()}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/40" aria-hidden="true" />

      <div className="fixed z-[99999] inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-xl flex flex-col items-center h-1/2 rounded bg-white px-10 py-10">
          <Dialog.Title className="text-4xl font-semibold text-primary-500 py-8">
            관리자 로그인
          </Dialog.Title>
          <Dialog.Description className="w-full h-full flex flex-col items-start gap-4">
            <div className="w-full">
              <div className="text-lg text-primary-400 font-light pb-2">
                아이디
              </div>
              <input
                ref={idRef}
                type="text"
                placeholder="아이디"
                className="w-full text-xl px-2 py-1 rounded-lg border border-primary-200"
              />
            </div>
            <div className="w-full">
              <div className="text-lg text-primary-400 font-light pb-2">
                비밀번호
              </div>
              <input
                ref={passwordRef}
                type="password"
                placeholder="비밀번호"
                className="w-full text-xl px-2 py-1 rounded-lg border border-primary-200"
              />
            </div>

            <div className="mt-auto flex gap-4 justify-center">
              <button
                type="button"
                className="text-xl text-white bg-primary-400 rounded-full px-6 py-1"
                onClick={handleProceedLogin}
              >
                로그인
              </button>
              <button
                type="button"
                className="text-xl text-primary border border-primary-400 rounded-full px-6 py-1"
                onClick={() => onClose && onClose()}
              >
                취소
              </button>
            </div>
          </Dialog.Description>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
