import React from 'react'
import { Element } from 'react-scroll'

import approveImg from '../assets/undraw_approve_qwp7.svg'
import telImg from '../assets/Phone_fill.png'
import emailImg from '../assets/E-mail.png'
import officeImg from '../assets/Pin_alt_fill.png'
import SectorImg1 from '../assets/undraw_online_articles.svg'
import SectorImg2 from '../assets/undraw_certificate.svg'
import SectorImg3 from '../assets/undraw_around_the_world.svg'
import SectorImg4 from '../assets/undraw_electricity.svg'
import CareerItem from '../components/CareerItem'
import SectionTitle from '../components/SectionTitle'
import profilePicture from '../assets/profile.jpeg'
import { Link } from 'react-router-dom'

export default function HomePage() {
  return (
    <main>
      <Element name="home" className="relative h-screen">
        <div className="absolute px-8 sm:px-12 md:px-16 lg:px-24 z-10 inset-0 flex flex-col pt-28 md:pt-0 md:justify-center gap-3 container mx-auto">
          <div className="text-black text-2xl md:text-3xl font-extralight">
            IT/기술 특허 컨설팅 전문
          </div>
          <div className="text-primary-700 md:text-primary text-3xl md:text-5xl">
            변리사{' '}
            <span className="text-5xl md:text-6xl font-semibold">전 종 성</span>{' '}
            입니다.
          </div>
        </div>

        <img
          src={profilePicture}
          className="absolute right-0 h-full object-cover w-full md:w-2/5 brightness-110"
        />
        <div className="absolute right-0 inset-y-0 h-full w-full md:w-2/5 z-40 bg-transparent md:bg-gradient-to-r from-white to-transparent to-50%" />
      </Element>

      <Element
        name="intro"
        className="bg-primary-500 text-white py-24 md:py-36"
      >
        <div className="container mx-auto px-12 md:px-24">
          <div className="flex justify-center gap-48 items-center">
            <img src={approveImg} className="w-80 h-auto hidden md:block" />
            <div>
              <div className="text-xl sm:text-2xl md:text-4xl font-extralight pb-4">
                여러분의 소중한 아이디어가 보호받을 수 있도록
              </div>
              <div className="text-3xl sm:text-4xl md:text-5xl font-bold">
                함께 도와드리겠습니다
              </div>
            </div>
          </div>

          <div className="flex items-center gap-4 my-16">
            <hr className="border-primary-200 w-full" />
            <div className="font-light flex-shrink-0">
              JESI 대표 <span className="font-semibold">전 종 성</span>
            </div>
            <hr className="border-primary-200 w-full" />
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-3 justify-center md:text-xl">
            <div className="text-center">
              <div className="flex items-center justify-center gap-2 font-semibold pb-4">
                <img className="" src={telImg} /> TEL
              </div>
              <div className="font-extralight">010-2242-3421</div>
            </div>

            <div className="text-center">
              <div className="flex items-center justify-center gap-2 font-semibold pb-4">
                <img className="" src={emailImg} /> E-MAIL
              </div>
              <a
                className="font-extralight underline"
                href="mailto:jasonjun@daum.net"
              >
                jasonjun@daum.net
              </a>
            </div>

            <div className="text-center">
              <div className="flex items-center justify-center gap-2 font-semibold pb-4">
                <img className="" src={officeImg} /> OFFICE
              </div>
              <div className="font-extralight text-center leading-relaxed">
                서울시 관악구 행운2길 1<br />
                금문B/D 705호
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name="career" className="bg-primary-100 py-8 md:py-12">
        <div className="container mx-auto px-6 sm:px-12 md:px-24 lg:px-32">
          <SectionTitle title="경력" className="pb-12" />

          <div className="flex flex-col gap-8 md:gap-16 text-primary pb-16">
            <CareerItem
              title="산업통산지원부 과장"
              detail="(산업기술, 지역산업, 국표원 등)"
            />
            <CareerItem
              title="특허청 특허심사관"
              detail="(디지탈기기, 통신시스템)"
            />
            <CareerItem title="방위사업청 과장, 지식재산위원회 과장, 정보통신부 사무관" />
            <CareerItem title="재외공간 주재관" detail="(산업, 자원, 에너지)" />
            <CareerItem title="KOTRA 투자종합실장" />
            <CareerItem title="한국전기안전공사 부사장" />
          </div>
        </div>
      </Element>

      <Element name="businessSector" className="bg-white py-8 md:py-12">
        <div className="container mx-auto px-6 sm:px-12 md:px-24 lg:px-32">
          <SectionTitle title="지원분야" className="pb-12" />

          <div className="flex justify-center text-primary pb-16">
            <div className="w-full xl:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-8 text-center">
              <div className="col-span-1 flex flex-col gap-12 justify-center items-center text-white bg-primary-400 h-80 rounded-3xl p-12">
                <img src={SectorImg1} className="h-2/3" />
                <div className="text-xl sm:text-2xl font-medium">
                  정부과제 (R&D, 프로젝트)
                </div>
              </div>
              <div className="col-span-1 flex flex-col gap-12 justify-center items-center text-white bg-primary-400 h-80 rounded-3xl p-12">
                <img src={SectorImg2} className="h-2/3" />
                <div className="text-xl sm:text-2xl font-medium">
                  지재권, 특허출원 / 분쟁자문
                </div>
              </div>
              <div className="col-span-1 flex flex-col gap-12 justify-center items-center text-white bg-primary-400 h-80 rounded-3xl p-12">
                <img src={SectorImg3} className="h-2/3" />
                <div className="text-xl sm:text-2xl font-medium">
                  표준인증 / 해외진출 (ODA)
                </div>
              </div>
              <div className="col-span-1 flex flex-col gap-12 justify-center items-center text-white bg-primary-400 h-80 rounded-3xl p-12">
                <img src={SectorImg4} className="h-2/3" />
                <div className="text-xl sm:text-2xl font-medium">
                  전기 / 에너지 프로젝트 수주
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name="askfor" className="bg-primary-200 text-primary-600">
        <div className="container mx-auto px-6 sm:px-12 md:px-24 lg:px-32 py-48 text-center">
          <div className="text-3xl sm:text-4xl md:text-6xl font-bold pb-6">
            궁금한 점이 있으신가요?
          </div>
          <div className="text-xl sm:text-2xl md:text-4xl font-light">
            질의응답 게시판에서 바로 문의하세요!
          </div>
          <hr className="w-full border-primary-300 my-12 md:my-24" />
          <Link to="/qna">
            <button
              type="button"
              className="bg-primary rounded-3xl px-8 py-3 font-semibold text-xl md:text-2xl text-white"
            >
              지금 문의하기
            </button>
          </Link>
        </div>
      </Element>
    </main>
  )
}
